import React,{Component} from "react";
import css_classes from '../style.module.css';
import styles from "../../Starmus8/style.module.css";
import banner_info from "../../Starmus8/images/banner_info.png";
import Figure from "../../components/Figure";
import {Switcher} from "../../components/Switcher/Switcher";
import Configs from "../../configs";

export  class ArtistsSpeakers extends Component{
    constructor(props) {
        super();
        this.content = props.content;
        this.state = {switcher_buttons:[{id:'speaker',title:"Speakers",},{id:'artist',title:"Artists"}],speakers:[]}

    }

    componentDidMount() {
        document.getElementById('main').scrollTo(0,0);
        this.getData();
    }
    getData = (key="speaker")=> {
        fetch(`${Configs.api_url}data/get-persons?type=${key}&festival_id=${this.content.festival_id}`)
            .then(response => response.json())
            .then(data => {
                    this.setState({
                        speakers: data.data.persons,
                    });
                }
            );
    }
    switchType = (type) => {
        this.getData(type);
    }


    render() {
        let keys = this.state.switcher_buttons;
        let speakers = [];
        let camp_artists =[];
        for (let speaker of this.state.speakers) {
            if(parseInt(speaker.is_camp_artist)){
                camp_artists.push(<Figure info={speaker}/>)
            }else{
                speakers.push(<Figure info={speaker}/>)
            }

        }
        if(camp_artists.length>0){
            speakers = <>
                {speakers}
                <div className={css_classes.camp_artists_title}>STARMUS CAMPS</div>
                {camp_artists}
            </>
        }
        let switcher='';
        if(this.state.switcher_buttons.length){
            switcher=<Switcher keys={keys} switcherFunction={this.switchType}></Switcher>
        }

        return <div className={'section_60 am_container text_center'}>

            {switcher}
            <div className={css_classes.speakers_list}>
                {speakers}
            </div>
        </div>
    }
}