import React, {Component} from "react";
import css_classes from '../style.module.css';
import {Follow} from "./Follow";
import SubscribeForm from "../../components/Forms/SubscribeForm";
import FaceBookIcon from "../images/soc_fb.png";
import TwitterIcon from "../images/soc_x.png";
import InstagramIcon from "../images/soc_insta.png";
import YouTubeIcon from "../images/soc_yt.png";
import TiktokIcon from "../images/soc_tiktok.png";
import RegForm from "../../components/Forms/RegForm";

export class ButtonWithBg extends Component {

    constructor(props) {
        super();
        this.content = props.content;
    }

    render() {
        console.log(this.content);
        return <div className={css_classes.page + ' ' + css_classes.flex_column}>


            <div className={css_classes.button_with_bg_wrap} style={{backgroundImage:`url(${this.content.image})`}}>

                <div className={css_classes.title} dangerouslySetInnerHTML={{__html:this.content.title}}></div>
                <div className={css_classes.sub_title} dangerouslySetInnerHTML={{__html:this.content.sub_title}}></div>
                {this.content.button_url==='/reg-form' && <button className={css_classes.reg_button} onClick={() => {
                    this.props.openPopup(<RegForm></RegForm> )
                }}>{this.content.button_text}
                </button> }
                {!['/reg-form'].includes(this.content.button_url) &&
                    <a href={this.content.button_url} className={css_classes.button_with_bg_btn}
                       dangerouslySetInnerHTML={{__html: this.content.button_text}}></a>
                }

            </div>

        </div>
    }
}