import React, {Component} from "react";
import css_classes from '../style.module.css';
class Section2 extends Component{


    constructor(props) {
        super();
    }

    render(props){

        return <div className={css_classes.section2}>
            <div className={"am_container text_justify "+css_classes.section2_container}>
                <p className={"text_1040 "+css_classes.section2_text}>
                    Every edition of the festival is organised around a particular theme related to space exploration and includes talks, debates, panel discussions, exhibitions, concerts, film screenings and other activities. Starmus also offers a City Programme, a morning agenda of talks, presentations, workshops, screenings, etc. all around the city and available freely to general public.
                    Each festival is followed by the publication of a beautifully-designed book containing the lectures of al the speakers accompanied by excellent pictures. Every edition of the festival has granted the attendees a unique experience, each in its own way, a symbiosis between exclusive talks and once-in-a-lifetime concerts.</p>

            </div>
        </div>;
    }
}
export default Section2;