import styles from "./style.module.css";
import React, {Component} from 'react';
import Configs from "../../configs";
import Checkbox from "@material-ui/core/Checkbox";
export default class RegForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            fields: {},
            errors: {},
            step:1,
            done:false,
            is_agree:false
        }
        this.handleIsAgreeChange = this.handleIsAgreeChange.bind(this);
    }

    handleIsAgreeChange(event) {
        this.setState({is_agree: event.target.checked, is_agree_error: !event.target.checked})
    }
    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Name
        if(!fields["firstname"]){
            formIsValid = false;
            errors["firstname"] = "Cannot be empty";
        }
        if(!fields["lastname"]){
            formIsValid = false;
            errors["lastname"] = "Cannot be empty";
        }
        if(!fields["phone"]){
            formIsValid = false;
            errors["phone"] = "Cannot be empty";
        }
        if(!fields["country"]){
            formIsValid = false;
            errors["country"] = "Cannot be empty";
        }

        //Email
        if(!fields["email"]){
            formIsValid = false;
            errors["email"] = "Cannot be empty";
        }
        if(typeof fields["email"] !== "undefined"){
            if(!fields["email"].match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/)){
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    formSubmit(e){
        e.preventDefault();
        if(this.handleValidation()){
            window.gtag('event', 'conversion', {'send_to': 'AW-16507181171/c_4iCMjYw54ZEPOwnr89'});

            fetch(`${Configs.api_url}data/pre-reg`, {
                method:'post',
                body:JSON.stringify(this.state.fields)
            })
                .then(response => response.json())
                .then(data => this.setState({
                    person: data.data.person,
                }));
            this.setState({step:3,errors:{},fields:{firstname:'',lastname:'',email:'',country:''}});
        }else{
            this.setState({success_message:false});
        }

    }

    handleChange(field, e){
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({fields});
    }
    goStep(step){
        this.setState({step})
    }
    render() {
        return (
            <div className={styles.preRegWrap}>
                {this.state.step===1 && <div className={"text_center"}>
                    <div className={styles.preRegTitle}>ACCREDITATION
                        <div className={styles.preRegText}>STARMUS LA PALMA will feature 2 STARMUS CAMPS + 2 CONCERTS + KEYNOTE LECTURES</div>
                    </div>

                    <ul className={styles.preRegUl}>
                        <li>This is the only event with a capacity limit at STARMUS La Palma. The rest of the festival events - STARMUS CAMP and CONCERTS - do not require pre-registration. Please note that most speakers will also be giving lectures at the CAMPS, so make sure you don't miss it!</li>
                        <li><span>All Starmus events are free and open to the public.</span></li>
                        <li><span>However, </span><span><b>limitations apply to LECTURES&nbsp;</b></span><span>due to the capacity of the venues under consideration.&nbsp;</span>
                        </li>
                        <li> Fill in this form to <b>request accreditation&nbsp;</b><b>to attend the LECTURES </b>program.
                        </li>
                        <li><span>Accreditations will be distributed on a first-com, first-served basis, until full capacity is reached.</span>
                        </li>
                        <li><span><span><b>Concerts and Starmus Camps&nbsp; do not require accreditation</b></span><br/></span>
                        </li>
                    </ul>
                    <div className={styles.new_info}><span className={'color_orange'}>*</span> The accreditation process for pre-registered attendees to the keynote lectures has begun. Those who have registered will be informed via email by the organization. Please check your inbox!</div>

                    <img className={styles.preRegImg} src={require("./images/reg_s8_img.jpg")}/>
                    <button className={'blue_btn'} onClick={()=>{ this.goStep(2)}}>Next</button>
                </div>}
                {this.state.step === 2 && <>
                    <div className={"text_center " + styles.preRegTitle}>Pre-register for Starmus Lectures</div>
                    <form name="preregForm" className="s7_form" onSubmit={this.formSubmit.bind(this)}>
                        <div className={"s7_form_group"}>
                            <label>First Name</label>
                            <input className={"s7_input"} ref="firstname" type="text" size="60" placeholder="Firstame"
                                   onChange={this.handleChange.bind(this, "firstname")} value={this.state.fields["firstname"]}/>
                            {this.state.errors["firstname"] && <div className="form_error">{this.state.errors["firstname"]}</div>}
                        </div>
                        <div className={"s7_form_group"}>
                            <label>Last Name</label>
                            <input className={"s7_input"} ref="lastname" type="text" size="60" placeholder="Lastame"
                                   onChange={this.handleChange.bind(this, "lastname")} value={this.state.fields["lastname"]}/>
                            {this.state.errors["lastname"] && <div className="form_error">{this.state.errors["lastname"]}</div>}
                        </div>
                        <div className={"s7_form_group"}>
                            <label>Email</label>
                            <input ref="email" type="text" size="30" placeholder="Email"
                                   onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]}/>
                            {this.state.errors["email"] && <div className="form_error">{this.state.errors["email"]}</div>}
                        </div>
                        <div className={"s7_form_group"}>
                            <label>Phone</label>
                            <input ref="phone" type="text" size="30" placeholder="Phone"
                                   onChange={this.handleChange.bind(this, "phone")} value={this.state.fields["phone"]}/>
                            {this.state.errors["phone"] && <div className="form_error">{this.state.errors["phone"]}</div>}
                        </div>
                        <div className={"s7_form_group"}>
                            <label>Country</label>
                            <input ref="country" type="text" size="30" placeholder="Country"
                                   onChange={this.handleChange.bind(this, "country")} value={this.state.fields["country"]}/>
                            {this.state.errors["country"] && <div className="form_error">{this.state.errors["country"]}</div>}
                        </div>
                        <div className={"s7_form_group"}>
                            <div className={'flex'}>
                                <Checkbox
                                    className={styles.agree_checkbox}
                                    name={'is_agree'}
                                    checked={this.state.is_agree}
                                    onChange={this.handleIsAgreeChange}
                                    inputProps={{'aria-label': 'checkbox'}}
                                />
                                <div className={styles.agree_text}>
                                    I accept Starmus Universe <a href={"https://starmus.com/static/media/pdf2.ae589db9.pdf"} className={'orange_link'}>Privacy Statement</a>  and consent to my data being used for event communication and updates.

                                </div>
                            </div>
                        </div>
                        <div className={"text_center"}>
                            <button disabled={!this.state.is_agree} type={"submit"} className={'blue_btn'}>Register</button>
                        </div>
                        {/*<span className="form_success">{this.state.success_message}</span>*/}
                    </form>
                </>}
                {this.state.step === 3 && <div className={"text_center"}>
                    <img className={styles.preRegGif} src={require("./images/thanks.gif")}/>
                    <div className={styles.preRegTitle}>Thank you for subscribe
                        <div className={styles.preRegText}>We are thrilled to welcome you to the STARMUS community! Your
                            registration for the “STARMUS” has been successfully received.</div>
                    </div>
                </div>}

            </div>
        );
    }
}
