import React, {Component} from "react";
import Configs from "../configs";
import css_classes from "./style.module.css"
import {Switcher} from "../components/Switcher/Switcher";
import Figure from "../components/Figure";

export class Artists extends Component {

    constructor(props) {
        super();
        this.state = {switcher_buttons:[],artists:[]}
    }

    componentDidMount() {
        document.getElementById('main').scrollTo(0,0);
        this.getData();
    }
    getData = (festival_id=0)=> {
        fetch(`${Configs.api_url}data/get-persons?type=artist&festival_id=`+festival_id)
            .then(response => response.json())
            .then(data => {
                    this.setState({
                        switcher_buttons: data.data.festivals,
                        artists: data.data.persons,
                    });
                }
            );
    }
    switchYear = (festival_id) => {
        this.getData(festival_id);
    }
    render() {
        let keys = this.state.switcher_buttons;
        let artists = [];
        let camp_artists =[];
        for (let artist of this.state.artists) {
            if(parseInt(artist.is_camp_artist)){
                camp_artists.push(<Figure info={artist}/>)
            }else{
                artists.push(<Figure info={artist}/>)
            }
        }
        if(camp_artists.length>0){
            artists = <>
                {artists}
                <div className={css_classes.camp_artists_title}>Camp Artists</div>
                {camp_artists}
            </>
        }

        let switcher='';
        if(this.state.switcher_buttons.length){
            switcher=<Switcher keys={keys} switcherFunction={this.switchYear}></Switcher>
        }

        return <React.Fragment>
            <div className={"am_wrapper page_main"}>
                <div className={css_classes.section_1}>
                    <div className={'am_container'}>
                        <div className={'section_title '+css_classes.section_1_title}>STARMUS ARTISTS</div>
                    </div>
                </div>
                <div className={'section_60 am_container text_center'}>

                    {switcher}
                    <div className={css_classes.artists_list}>
                        {artists}
                    </div>
                </div>
            </div>
        </React.Fragment>


    }
}
