import React, {Component} from "react";
import Configs from "../configs";
import css_classes from "./style.module.css"
import {PageTemplateSimple} from "./Components/PageTemplateSimple";
import {MainBanner} from "./Components/MainBanner";
import {PageTemplate2} from "./Components/PageTemplate2";
import {PageTemplate3} from "./Components/PageTemplate3";
import {PageTemplate4} from "./Components/PageTemplate4";
import {PageTemplate5} from "./Components/PageTemplate5";
import {OnlyImage} from "./Components/OnlyImage";
import {PageTemplate6} from "./Components/PageTemplate6";
import {PageTemplate7} from "./Components/PageTemplate7";
import {Follow} from "./Components/Follow";
import {Subscribe} from "./Components/Subscribe";
import {Partners} from "./Components/Partners";

import {Element} from 'react-scroll'
import ScrollDown from "../components/Scroll/ScrollDown";
import {Banner} from "./Components/Banner";
import {ArtistsSpeakers} from "./Components/ArtistsSpeakers";
import {Agenda} from "./Components/Agenda";
import {ButtonWithBg} from "./Components/ButtonWithBg";

export class Page extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        // this.isHome=props.isHome;
    }
    componentWillReceiveProps(nextProps) {
        document.getElementById('main').scrollTo(0,0);
        if (nextProps.match.params.slug !== this.props.match.params.slug) {
            const slug = nextProps.match.params.slug;
            this.getData(slug);
        }
    }

    componentDidMount() {
        // if(this.isHome){
        //     this.getData('_home');
        // }else{
            const {match: {params: {slug}}} = this.props;
            this.getData(slug);
        // }



    }
    getData(slug){
        // Loader.startLoading();
        // window.Loader.startLoading();
        fetch(`${Configs.api_url}data/get-page?slug=${slug}`)
            .then(response => response.json())
            .then(data => {

                    // window.Loader.stopLoading();
                    this.setState({
                        page:data.page,
                        content: data.content,
                    })
                }
            );
    }

    renderContent = (content,index) => {
        let renderedContent='';
        switch (content.template) {
            case 'festival_main_banner':
                renderedContent =  <MainBanner  openPopup={this.props.openPopup} content={content}/>;
                break;
            case 'banner':
                renderedContent =  <Banner content={content}/>;
                break;
            case 'page_template_2':
                renderedContent =  <PageTemplate2 content={content}/>;
                break;
            case 'page_template_3':
                renderedContent =  <PageTemplate3 content={content}/>;
                break;
            case 'page_template_4':
                renderedContent =  <PageTemplate4 content={content}/>;
                break;
            case 'page_template_5':
                renderedContent =  <PageTemplate5 content={content}/>;
                break;
            case 'only_image':
                renderedContent =  <OnlyImage content={content}/>;
                break;
            case 'page_template_6':
                renderedContent =  <PageTemplate6 content={content}/>;
                break;
            case 'page_template_7':
                renderedContent =  <PageTemplate7 content={content}  bg={'#1F1F1F'}/>;
                break;
            case 'page_template_8':
                renderedContent =  <PageTemplate7 content={content} bg={'white'}/>;
                break;
            case 'button_with_bg':
                renderedContent =  <ButtonWithBg openPopup={this.props.openPopup} content={content}/>;
                break;
            case 'follow':
                renderedContent =  <Subscribe  openPopup={this.props.openPopup} content={content}/>;
                break;
            case 'partners':
                renderedContent =  <Partners festival_id={content.festival_id}/>;
                break;
            case 'simple':
                renderedContent =  <PageTemplateSimple content={content}/>;
                break;
            case 'page_with_gallery':
                renderedContent =  <PageTemplateSimple content={content}/>;
                break;
            case 'artist_speakers':
                renderedContent =  <ArtistsSpeakers content={content}/>;
                break;
            case 'agenda':
                renderedContent =  <Agenda content={content}/>;
                break;

        }

        if(this.state.page.is_home){
            return <Element className={!["partners",'button_with_bg'].includes(content.template) ?css_classes.home_container:css_classes.relative} name={'page_'+index}>

                {renderedContent}
                {this.state.content[index+1] && <ScrollDown  to={'page_'+(index+1)} containerId="main"/>}
            </Element>
        }else{
            return <div>{renderedContent}</div>
        }
    };

    render() {
        if (this.state.page !== undefined) {
            let content='';
            if(this.state.page.template==='parent_page'){
                 content = this.state.content.map(this.renderContent);
            }else{
                 content = this.renderContent(this.state.page);
            }

            return <React.Fragment>
                <div key ={this.state.page.id} className={"am_wrapper page_main new_page_container"}>
                    {/*<Section1  fest={this.state.page}/>*/}

                    {content}

                </div>
            </React.Fragment>
        }
        return '';


    }
}
